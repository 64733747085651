import React, { Fragment } from "react";
import styles from "./RecentBenefitUsage.module.scss";
import { HeroBanner } from "components/HeroBanner";
import { IntuitiveMessages } from "components/IntuitiveMessages";
import { Section } from "components/Section";
import classnames from "classnames";
import { getDateByProvidedFormat } from "utlis/generalUtils";
import * as errorMessages from "config/errorMessages";
import { NO_USAGE_DATA } from "config/errorCodeConstants";
import { benefitMapping } from "config/benefitUsageConfig";
import { DEFAULT_CURRENCY } from "config/constants";
import { ErrorBoundary } from "components/ErrorBoundary";

interface RecentBenefitUsageProps {
  pageContent: any;
  tableContent: any[];
  errorContent: any;
}
const RecentBenefitUsage: React.FC<RecentBenefitUsageProps> = (props) => {
  const { pageContent, tableContent, errorContent } = props;

  const getUsageDataList = (usageDetails) => {
    let {
      usageType,
      bookingType,
      eventCategory,
      featureName,
      ticketCount,
      orderAmount,
      discountAmount,
      settlementAmount,
      airportDesc,
      travelLocation,
      passengerCount,
      claimAmount,
    } = usageDetails;

    let replaceableString = {
      AirportDescription: airportDesc,
      BookingType: bookingType,
      DefaultCurrency: DEFAULT_CURRENCY,
      ClaimAmount: claimAmount,
      ClaimType: `<Claim Type>`,
      DiscountAmount: discountAmount,
      DiscountValue: discountAmount,
      EventCategory: eventCategory,
      Location: travelLocation,
      OrderAmount: orderAmount,
      PassengerCount: passengerCount,
      SettlementAmount: settlementAmount,
      TicketCount: ticketCount,
      TravelDestination: travelLocation,
      x: passengerCount,
    };

    let FEATURE_NAME = featureName.toUpperCase();
    let type = usageType ? usageType.toUpperCase() : "DEFAULT";

    let USAGE_TYPE =
      FEATURE_NAME === "AIRLOUNGEPPS" && orderAmount > 0
        ? "TRANSACTION_N"
        : type;

    let usageString =
      benefitMapping[FEATURE_NAME] && benefitMapping[FEATURE_NAME][USAGE_TYPE];

    let replacedValueString = new RegExp(
      `${Object.keys(replaceableString).join("|")}`,
      "gi"
    );

    replacedValueString =
      usageString &&
      usageString.replace(replacedValueString, function (matched) {
        return replaceableString[matched];
      });

    let finalBenefitString =
      replacedValueString && replacedValueString.toString().replace(/#/g, "");

    return finalBenefitString ? finalBenefitString : null;
  };

  const renderZeroDataOrError = () => {
    let errorCode = errorContent[0];
    let message;
    switch (errorCode) {
      case NO_USAGE_DATA:
        message = errorMessages.BENEFIT_NOT_FOUND;
        break;
      default:
        message = errorMessages.DEFAULT_ERROR_MESSAGE;
        break;
    }

    return (
      <tr>
        <td className={styles.errorMessage}>{message}</td>
      </tr>
    );
  };

  const renderCustomWidthWrapper = (componentData, index) => {
    return (
      <div
        className={classnames(styles.recentBenefit__row)}
        key={"recentUsageWrapper" + index}
      >
        {componentData.content.map((ele, index) => {
          return (
            <ErrorBoundary key={index}>
              {allTypesMapping[ele.type](ele.metaData, index)}
            </ErrorBoundary>
          );
        })}
      </div>
    );
  };
  const renderHeroBanner = (heroBannerContent, index) => {
    return (
      <HeroBanner
        metaData={heroBannerContent}
        key={"recentUsageBanner" + index}
      />
    );
  };

  const renderSingleSection = (sectionContent, index) => {
    let usageString;
    return (
      <div className={classnames(styles.recentBenefit__col)}>
        <Section metaData={sectionContent} id={index} />
        <div className={styles.recentBenefit__table}>
          <table>
            <tbody>
              {tableContent.length > 0
                ? tableContent.map((item, idx) => {
                    usageString = getUsageDataList(item);
                    return usageString ? (
                      <tr key={`row_${idx}`}>
                        <td>{usageString}</td>
                        <td>
                          {getDateByProvidedFormat(
                            item.usageDate,
                            "dd-mm-yyyy",
                            "/"
                          )}
                        </td>
                      </tr>
                    ) : null;
                  })
                : renderZeroDataOrError()}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const renderIntuitiveMessages = (msgContent, index) => {
    return (
      <div className={styles.recentBenefit_content}>
        <div className={styles.recentBenefit__col}>
          <IntuitiveMessages metaData={msgContent} id={index} />
        </div>
      </div>
    );
  };
  const allTypesMapping = {
    HeroBanner: renderHeroBanner,
    IntuitiveMessages: renderIntuitiveMessages,
    Section: renderSingleSection,
    CustomWidthWrapper: renderCustomWidthWrapper,
  };
  return props.pageContent && props.pageContent.length > 0 ? (
    <Fragment>
      <div className={styles.recentBenefit__wrapper}>
        {pageContent.map((pageElement, id) => {
          return allTypesMapping[pageElement.type](pageElement.metaData, 1);
        })}
      </div>
    </Fragment>
  ) : null;
};
export default RecentBenefitUsage;
