import { Component } from "react";
import { connect } from "react-redux";
import RecentBenefitUsage from "./RecentBenefitUsage";
import { graphQlCall, onGqlError } from "services/GraphQL/gqlHelper";
import { GET_USAGE_HISTORY } from "services/GraphQL/gqlQueries";
import { getBrandName, getAccountType } from "services/RestAPI/exploreCalls";
import { get } from "services/RestAPI/apiUtil";
import { BreadCrumbModel } from "redux/models/BreadCrumbModel";
import { BrandSpecificDataModel } from "redux/models/BrandSpecificDataModel";
import { SelectedAccountModel } from "redux/models/SelectedAccountModel";
interface recentBenefitProps {
  pageContent: any;
  tableContent: any;
  errorContent: any;
}

class RecentBenefitContainer extends Component<
  { correlationDetails; tenantDetails; selectedAccount },
  recentBenefitProps
> {
  constructor(props) {
    super(props);
    this.state = {
      pageContent: {},
      tableContent: [],
      errorContent: {},
    };
  }

  componentDidMount() {
    this.getUsagePage();
    this.populateBreadCrumbs();
    this.getUsageHistory();
  }

  populateBreadCrumbs = () => {
    let breadCrumbData = [
      {
        path: "",
        name: "Recent Benefit Usage",
      },
    ];
    new BreadCrumbModel({ id: "1", breadCrumbData: breadCrumbData }).$save();
  };

  getUsagePage = async () => {
    let path =
      process.env.PUBLIC_URL +
      `/recent-usage-history/${getBrandName()}/${getAccountType()}/recent-usage-history.json`;
    await get(path).then((response) => {
      let pageData = response.data && response.data.data;
      this.setState({ pageContent: pageData });
    });
  };

  getUsageHistory = async () => {
    const {
      tenantDetails: {
        props: { tenantId },
      },
      selectedAccount,
    } = this.props;
    const { memberNumber } = selectedAccount && selectedAccount.props;
    return await graphQlCall(
      "query",
      GET_USAGE_HISTORY,
      {
        memberNumber: memberNumber,
      },
      {
        tenantId: tenantId,
      }
    )
      .then((response) => {
        let { benefitHistory } = response && response.data;
        this.setState({
          tableContent: benefitHistory,
        });
      })
      .catch((errors) => {
        this.setState({ errorContent: onGqlError(errors) });
      });
  };
  render() {
    const { pageContent, tableContent, errorContent } = this.state;
    return (
      <RecentBenefitUsage
        pageContent={pageContent}
        tableContent={tableContent}
        errorContent={errorContent}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenantDetails: BrandSpecificDataModel.getInsatnce("1"),
    selectedAccount: SelectedAccountModel.getInsatnce("1"),
  };
};

export default connect(mapStateToProps)(RecentBenefitContainer);
