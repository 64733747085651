export const GENERAL_ERROR_MESSAGE = `The sign in details you entered don't match our records, please try again. Your account might have been locked out. In the case it is locked out
then please wait 24 hours.`;
export const MESSAGE_OFFLINE_ACCOUNT = `This is an offline account and not eligible for online access.`;
export const NOT_FOUND_MESSAGE = `The requested operation failed because a resource associated with the request could not be found.`;
export const DEFAULT_ERROR_MESSAGE = `Our site is currently experiencing technical problems. Please try again later.`;
export const ERROR_BOX_HEADER = `It looks like something has gone wrong. Please check the error below before proceeding.`;
export const LOGIN_ERROR_BOX_HEADER = 'It looks like something has gone wrong.';
export const CAPTCH_MESSAGE = `Captcha information missing. Please try again.`;
export const EMAIL_REQUIRED_ERROR = `Email is mandatory`;
export const EMAIL_PATTERN_ERROR = `Enter your email address in the correct format (for example, name@domain.com)`;
export const PASSWORD_REQUIRED_ERROR = `Password is mandatory`;
export const CURRENT_EMAIL_ERROR = `You must enter your current email address`;
export const PASSWORD_INCORRECT_MESSAGE = `The current password you have entered is incorrect. Please try again.`;
export const OLDPASSWORD_REQUIRED_MESSAGE = `We cannot process your request. Please enter your current password in order to update your profile.`;
export const PASSWORD_VALIDATION_MIN_CHARS = `Password must be at least 8 characters long`;
export const PASSWORD_VALIDATION_MAX_CHARS = `Maximum allowed characters are 80`;
export const PASSWORD_VALIDATION_LOWERCASE_CHAR = `at least one lowercase char"`;
export const PASSWORD_VALIDATION_UPPERCASE_CHAR = `at least one uppercase char`;
export const PASSWORD_VALIDATION_NUMBER = `at least one number should be present in the password`;
export const PASSWORD_VALIDATION_REQUIRED = `You must enter your password in both boxes.`;
export const PASSWORD_VALIDATION_CONSECUTIVE_CHARS_NUM = `Your password cannot include more than 3 consecutive letters or numbers, or the word 'password'.`;
export const PASSWORD_VALIDATION_SPECIAL_CHARACTER = `Your password can only contain letters and numbers - no special characters.`;
export const PASSWORD_VALIDATION_SPACE_CHARACTER = `Your password cannot contain spaces.`;
export const PASSWORD_MATCH_CONFIRM = `The passwords you entered are not the same. Please try again.`;
export const EMAIL_REQUIRED_VALIDATION = `You must enter your new email address in both boxes.`;
export const EMAIL_MISMATCH_ERROR = `The email addresses you entered are not the same. Please try again.`;
export const INFO_INCORRECT_FOR_EMAIL_RECOVERY = `We couldn't find an account using these details. Please try again. If this doesn't work, call Membership Services.`;
export const FORGOT_EMAIL_NAME_VALIDATION_LASTNAME = `Your last name should contain only letters, dash (-) or apostrophe(').`;
export const FORGOT_EMAIL_NAME_VALIDATION_FIRSTNAME = `Your first name should contain only letters, dash (-) or apostrophe(').`;
export const FORGOT_EMAIL_FIRSTNAME_ERROR = `First Name is a mandatory field.`;
export const FORGOT_EMAIL_LASTNAME_ERROR = `Last Name is a mandatory field.`;
export const BIRTHDAY_MIN_DIGIT_ERROR = `Birth day should be atleast 1 digit`;
export const BIRTHDAY_DIGIT_ERROR = `Birth Day should be a number (1 - 31).`;
export const BIRTHDAY_ERROR = `Birth Day is a required field.`;
export const BIRTHMONTH_DIGIT_ERROR = `Birth Month should be a number (1 - 12).`;
export const BIRTHMONTH_ERROR = `Birth Month is a mandatory field.`;
export const BIRTHYEAR_DIGIT_ERROR = `Birth Year should be a 4 digit number.`;
export const BIRTHYEAR_ERROR = `Birth Year is a required field.`;
export const SORTCODE_NUMBER_ERROR = `Your sort code should contain only numbers.`;
export const SORTCODE_ERROR = `Sort code is a mandatory field.`;
export const ACCOUNT_EXACT_NUMBER_ERROR = `Your account number must contain 8 numbers.`;
export const ACCOUNT_NUMBER_MINMAX_ERROR = `Your account number must contain 8 numbers.`;
export const ACCOUNT_NUMBER_ERROR = `Account number is a mandatory field.`;
export const FIRSTNAME_REQUIRED = `First name is a mandatory field`;
export const LASTNAME_REQUIRED = `Last name is a mandatory field`;
export const BIRTH_DATE_REQUIRED = `Date of birth is a mandatory field`;
export const BIRTH_MONTH_REQUIRED = `Birth month is a mandatory field`;
export const BIRTH_YEAR_REQUIRED = `Birth year is a mandatory field`;
export const SORT_CODE_REQUIRED = `Your sort code should contain only numbers.`;
export const BIRTH_DAY_MIN = `Birth day should be atleast 1 digit`;
export const BIRTH_DAY_MAX = `Birth day should have max 2 digit`;
export const BIRTH_DAY_MATCHES = `Birth Day must be between 01 - 31.`;
export const BIRTH_MONTH_MIN = `Birth month should be atleast 1 digit`;
export const BIRTH_MONTH_MAX = `Birth month should have max 2 digit`;
export const BIRTH_MONTH_MATCHES = `Birth Month must be between 01 - 12.`;
export const BIRTH_YEAR_MIN = `Birth year must have 4 digit`;
export const BIRTH_YEAR_MAX = `Birth year must have 4 digit`;
export const BIRTH_YEAR_MATCHES = `Birth Year should be valid.`;
export const SORT_CODE_MATCHES = `Sort Code must be positive`;
export const SORT_CODE_MIN = `Each sort code field should contain exactly 2 digit`;
export const ACCOUNT_NUMBER_REQUIRED = `Account number is a mandatory field.`;
export const CONFIRM_EMAIL_MATCH = `Emails provided do not match!`;
export const CONFIRM_EMAIL = `Confirm Email is mandatory`;
export const CONFIRM_PASSWORD = "Confirm Password is mandatory";
export const EMAIL_ALREADY_EXIST = `Username already exists`;
export const MESSAGE_SIGNUP = `We couldn't find an account using these details. Please try again. If this doesn't work, call Membership Services.`;
export const MESSAGE_SIGNUP_ALREADY_EXISTS = `The email address you entered already exists. Please choose a different email address.`;
export const ACCOUNT_ERROR = `Your account number must contain 8 numbers.`;
export const FIRSTNAME_MATCH_ERROR = `Your first name should contain only letters, dash (-) or apostrophe(').`;
export const LASTNAME_MATCH_ERROR = `Your last name should contain only letters, dash (-) or apostrophe(').`;
export const UNPROCESSABLE_ENITTY = `The request is invalid. Check the API documentation to determine what parameters are supported for the request and to see if the request contains an invalid combination of parameters or an invalid parameter value.`;
export const ALREADY_MEMBER_ERROR = `Member is already web enabled. Please use your credentials to login into your account.`;
export const LWB_ERROR = 'Please check you are using the details you use to access your online or mobile banking and try again. For help, please visit your bank’s main website and click on Help and support.';
export const LWB_MEMBER_NOT_FOUND = 'Please check your account terms to confirm your account package includes these benefits.  If this is a newly opened account, it may take one working day after opening for all account details to be set up in our systems.  If you believe your account should be present, please call the appropriate number from the Contact Us link below.';
export const LWB_MEMBER_ACTIVATION_ERROR = 'Please check you are not already registered, and try again in a few minutes.  If the error recurs, please call the appropriate number from the Contact Us link below.';
export const LWB_TOKEN_VERIFICATION_ERROR = 'Token verification failed';
export const INVALID_OLD_PASSWORD =
  "The current password you have entered is incorrect. Please try again.";
export const PASSWORD_MATCH = `Passwords must match`;
export const RIO_NOT_FOUND = 'The sign in details you entered don’t match our records.';
export const EVENT_BOOKING_FORM_ERRORS = {
  HOUSE_NUMBER_NAME_REQUIRED_ERROR: "House number/name is a mandatory field.",
  POST_CODE_REQUIRED_ERROR: "Postcode is a mandatory field.",
  HOME_TELEPHONE_NUMBER_VALIDATION_ERROR:
    "The phone number must be between 8 and 16 characters in length, must contain only digits and must start with a 0.",
  NAME_OF_EVENT_REQUIRED_ERROR: "Name of event is a mandatory field.",
  DATE_OF_EVENT_REQUIRED_ERROR:
    "Date of event 1st choice is a mandatory field.",
  DATE_OF_EVENT2_REQUIRED_ERROR:
    "Date of event 2nd choice is a mandatory field.",
  DATE_OF_EVENT_VALIDATION_ERROR:
    "The event date must be provided and must be later than or equal to today.",
  DATE_OF_EVENT_CHOICE_ERROR:
    "The event date must be provided in given format and must be later than or equal to today.",
  NAME_OF_VENUE_REQUIRED_ERROR: "Name of venue is a mandatory field.",
  CITY_OF_VENUE_REQUIRED_ERROR: "City of venue is a mandatory field.",
  NUMBER_OF_TICKETS_REQUIRED_ERROR: "Number of tickets is a mandatory field.",
  NUMBER_OF_TICKETS_VALIDATION_ERROR: "Please enter a valid number.",
  SEATING_CHOICE_REQUIRED_ERROR: "Seating choice 1st is a mandatory field.",
};
export const PARTNER_PAGE_NOT_FOUND_MESSAGE = "Partner page not found!!!";
export const BOOKING_REF_REQUIRED_ERROR =
  "Booking Reference is a mandatory field.";
export const USER_LOCKED = `Your account might have been locked out. In the case it is locked out then please wait 24 hours.`;
export const BENEFIT_NOT_FOUND = `There is no usage data`;
export const INVALID_TOKEN =
  "Token ID provided is either Invalid or has expired";

  export const SIGN_UP_ERROR_MAPPING = {
    "HUB3001" : MESSAGE_SIGNUP,
    "HUB3002" : MESSAGE_SIGNUP,
    "HUB5002" : MESSAGE_SIGNUP,
    "HUB00014": ALREADY_MEMBER_ERROR,
    "HUB1005" : MESSAGE_SIGNUP_ALREADY_EXISTS,
    "HUB5000" : DEFAULT_ERROR_MESSAGE,
    "HUB3000" : DEFAULT_ERROR_MESSAGE,
    "HUB5003" : DEFAULT_ERROR_MESSAGE,
    "HUB0002" : DEFAULT_ERROR_MESSAGE,
    "HUB0003" : DEFAULT_ERROR_MESSAGE,
    "HUB1000" : DEFAULT_ERROR_MESSAGE,
    "HUB0008" : MESSAGE_OFFLINE_ACCOUNT,
  };

  export const FORGOT_EMAIL_ERROR_MAPPING = {
    "HUB3001" : MESSAGE_SIGNUP,
    "HUB3002" : MESSAGE_SIGNUP,
    "HUB3000" : DEFAULT_ERROR_MESSAGE,
    "HUB0001" : MESSAGE_SIGNUP,
    "HUB0002" : DEFAULT_ERROR_MESSAGE,
  };
  
  export const FORGOT_PASSWORD_ERROR_MAPPING = {
    "HUB3001" : MESSAGE_SIGNUP,
    "HUB3002" : MESSAGE_SIGNUP,
    "HUB3000" : DEFAULT_ERROR_MESSAGE,
    "HUB0009" : USER_LOCKED,
  };

  export const CHANGE_EMAIL_ERROR_MAPPING = {
    "HUB1005" : EMAIL_ALREADY_EXIST,
  };

  export const CHANGE_PASSWORD_ERROR_MAPPING = {
    "HUB1001" : INVALID_OLD_PASSWORD,
    "HUB1007" : INVALID_OLD_PASSWORD,
    401: INVALID_OLD_PASSWORD,
  };

  